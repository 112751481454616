$(document).foundation()

if screen.width > 1024

 # init controller
 controller = new (ScrollMagic.Controller)(globalSceneOptions:
   triggerHook: 'onEnter'
   duration: '200%')
 # build scenes
 new (ScrollMagic.Scene)(triggerElement: '#parallax1').setTween('#parallax1 > div',
   y: '80%'
   ease: Linear.easeNone).addIndicators().addTo controller

  #TOGGLERS
 # init controller
 controller = new (ScrollMagic.Controller)(globalSceneOptions: 
   triggerHook: 'onEnter'
   duration: 0)  
 # build scenes
 new (ScrollMagic.Scene)(triggerElement: '#sec1').setClassToggle('FadeInUp').addTo controller

    


